<template>
  <div class="wrapper-main__content">
    <div class="form-content">
      <div class="d-flex flex-wrap py-2 border-bottom">
        <VocabularyTitle :title="$t('sideBar.programs')" :per="paginationData.per_page" :entries="paginationData.total" />
        <PaginateWrapper v-if="paginationData" :last-page="paginationData.last_page" :current="filterPage" class="ml-auto" @pageClick="pageClick" />

        <!--Search-->
        <div class="d-flex justify-content-between gap-3 mt-3 w-100">
          <div class="d-flex gap-3">
            <search-dropdown v-model="selectedPerPage" @onSelect="pageClick(1)" />

            <MultiSelect
              v-model="selectedGenre"
              label="name"
              track-by="id"
              :options="filterGenresList"
              class="w-300px"
              :placeholder="$t('table.selectGenre')"
              @input="pageClick(1)"
            ></MultiSelect>
          </div>

          <search-input v-model="searchElements" @searchTable="pageClick(1)" />
        </div>
        <!--Search-->
      </div>

      <!--TABLE-->
      <div class="table-responsive vocabulary-table">
        <table class="table table-hover columns-resizable">
          <thead>
            <tr>
              <th style="min-width: 580px">
                <sort-arrows :sort-string="sortString" :column="'name,'" @click="sortTable('name,', $event)" />
                {{ $t('sideBar.programs') }}
              </th>
              <th style="min-width: 200px">
                <sort-arrows :sort-string="sortString" :column="'genre_id,'" @click="sortTable('genre_id,', $event)" />
                {{ $t('sideBar.genres') }}
              </th>
              <th>
                {{ $t('table.duration') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="programsStatus !== 'success'">
              <td colspan="3"><SpinnerLoader :loading="programsStatus" /></td>
            </tr>
            <template v-else>
              <tr v-for="(row, index) in tableData" :key="index">
                <td>
                  <div class="d-flex w-100 flex-fill">
                    <button v-if="canEdit" class="btn-icon" @click="showModalPrograms(row.id)">
                      <b-icon icon="pencil"></b-icon>
                      {{ $t('table.edit') }}
                    </button>
                    <button v-if="canDelete" class="btn-icon mr-4" @click="showModalDeletePrograms(row)">
                      <b-icon icon="trash"></b-icon>
                      {{ $t('table.delete') }}
                    </button>
                    {{ row.name }}
                  </div>
                </td>
                <td>
                  {{ row.genre ? row.genre.name : '' }}
                </td>
                <td>
                  {{ row.duration }}
                </td>
              </tr>
              <tr v-if="programsStatus === 'success' && !tableData.length">
                <td colspan="3">
                  <div class="d-flex w-100 justify-content-center">
                    {{ $t('table.noContent') }}
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--TABLE-->

      <!--FOOTER-->
      <div class="input-pagin">
        <b-button v-if="$checkPermissions('program.create')" variant="primary" @click="showModalPrograms()">
          {{ $t('table.addNewPrograms') }}
        </b-button>
      </div>
      <!--FOOTER-->

      <!--MODAL ADD/EDIT PROGRAM-->
      <b-modal
        id="programs-modal"
        ref="programs-modal"
        :title="modalType === 'add' ? $t('table.addNewPrograms') : $t('table.editPrograms')"
        :ok-disabled="$v.$invalid"
        :ok-title="modalType === 'add' ? $t('table.add') : $t('table.edit')"
        :cancel-title="$t('table.cancel')"
        :busy="isModalBusy"
        @ok.prevent="checkIfValidThenEnter"
      >
        <SpinnerLoader v-if="isModalLoading" :loading="isModalLoading ? 'loading' : 'success'" />
        <form v-else @submit.stop.prevent="checkIfValidThenEnter">
          <input type="submit" value="Submit" class="hidden-submit" />
          <b-form-group :label="$t('table.writeTitle')" label-for="input-program-name">
            <b-form-input
              id="input-program-name"
              v-model.trim="writeTitle"
              :placeholder="$t('table.enterTitle')"
              maxlength="255"
              autofocus
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group :label="$t('table.selectGenre')">
            <MultiSelect
              v-model.trim="modalSelectedGenre"
              label="name"
              track-by="id"
              :options="genres"
              :placeholder="$t('table.selectGenre')"
              data-automatic="ms-genre"
            ></MultiSelect>
          </b-form-group>

          <b-form-group :label="$t('table.writeDuration')" label-for="input-program-duration">
            <b-input-group>
              <b-input-group-prepend>
                <b-form-timepicker
                  id="timepicker-1"
                  v-model="writeDuration"
                  class="close-table-day-picker"
                  locale="ru"
                  button-only
                  button-variant="outline-secondary"
                  left
                  :label-close-button="$t('table.close')"
                  :label-no-time-selected="$t('table.noTime')"
                  :placeholder="$t('table.pickTime')"
                  :label-now-button="$t('datepicker.now')"
                  aria-controls="input-program-duration"
                  now-button
                  hide-header
                  required
                ></b-form-timepicker>
              </b-input-group-prepend>
              <the-mask
                id="input-program-duration"
                v-model="writeDuration"
                placeholder="HH:mm:ss"
                class="form-control"
                required
                mask="##:##:##"
                :masked="true"
              />
            </b-input-group>
            <div class="d-flex flex-wrap gap-2 mt-2 align-items-center">
              <small class="w-100 block">{{ $t('table.templates') }}:</small>
              <button
                v-for="(time, index) in durationTemplates"
                :key="index"
                type="button"
                class="btn btn-sm btn-outline-primary"
                @click="writeDuration = time"
              >
                <small>{{ time }}</small>
              </button>
            </div>
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
import MultiSelect from '@/components/MultiSelect';
import VocabularyTitle from '@/components/VocabularyTitle';
import SpinnerLoader from '@/components/SpinnerLoader';
import errorsHandler from '@/utils/errorsHandler';
import PaginateWrapper from '../components/PaginateWrapper';
import SearchDropdown from '../components/SearchDropdown';
import SearchInput from '../components/SearchInput';
import sortTableMixin from '@/mixins/sortTable.js';
import adminPanelQuery from '@/mixins/adminPanelQuery.js';
import SortArrows from '../components/SortArrows';

export default {
  name: 'Programs',
  components: { PaginateWrapper, SpinnerLoader, VocabularyTitle, MultiSelect, SearchDropdown, SearchInput, SortArrows },
  mixins: [sortTableMixin, adminPanelQuery],
  props: {
    q: { type: String, default: undefined },
    per_page: { type: [String, Number], default: undefined },
    on_page: { type: [String, Number], default: undefined },
    sort_mode: { type: String, default: undefined },
    genre_id: { type: [String, Number], default: undefined },
  },

  data() {
    return {
      tableData: [],
      paginationData: '',
      searchElements: '',
      filterPage: 1,
      selectedPerPage: '100',
      writeTitle: '',
      modalSelectedGenre: '',
      writeDuration: '',
      selectedGenre: '',
      filterGenresList: [],
      modalType: 'add',
      isModalLoading: true,
      isModalBusy: false,
      durationTemplates: ['00:14:59', '00:29:59', '00:44:59', '00:59:59', '01:29:59', '01:59:59'],
    };
  },
  computed: {
    ...mapGetters({
      isThemeHeader: 'isTheme',
      programs: 'getPrograms',
      programsStatus: 'getProgramsStatus',
      modalEditPrograms: 'getModalEditPrograms',
      genres: 'getGenresList',
    }),
    canEdit: function () {
      return this.$checkPermissions('program.update');
    },
    canDelete: function () {
      return this.$checkPermissions('program.delete');
    },
  },
  validations: {
    modalSelectedGenre: {
      required,
    },
    writeTitle: {
      required,
      maxLength: maxLength(255),
    },
    writeDuration: {
      required,
    },
  },
  watch: {
    programs() {
      this.setTableData();
    },
  },
  created() {
    document.title = this.$i18n.t('sideBar.programs') + ' – OpenMediaLogic';
  },
  mounted() {
    this.getProgramsInfo();
  },
  destroyed() {
    this.$store.commit('clearPrograms');
    this.$store.commit('clearGenresList');
  },
  methods: {
    async paramsData() {
      if (this.tableData) this.tableData.length = 0;
      await this.$store.dispatch('GET_PROGRAMS', {
        page: +this.filterPage,
        per_page: +this.selectedPerPage,
        'filter[name]': this.searchElements !== '' ? this.searchElements : null,
        sort: this.sortString ? this.sortString : null,
        'filter[genre_id]': this.selectedGenre ? this.selectedGenre.id : '',
        include: 'genre',
      });
      this.updateQuery();
    },

    pageClick(page) {
      this.filterPage = page;
      this.paramsData();
    },
    // get table info
    setTableData() {
      if (this.programs) {
        this.tableData = this.programs.data;
        this.paginationData = this.programs.pagination;
        if (this.filterPage > this.programs.pagination.last_page) {
          this.filterPage = this.programs.pagination.last_page;
          this.pageClick(this.filterPage);
        }
      }
    },
    async getProgramsInfo() {
      await this.$store.dispatch('GET_GENRES', { per_page: 1000 });
      this.filterGenresList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.genres];
      await this.restoreFromQuery('programs');
      this.paramsData();
    },

    clearFields() {
      this.writeDuration = '';
      this.modalSelectedGenre = '';
      this.writeTitle = '';
    },

    async showModalPrograms(data) {
      this.isModalLoading = true;
      this.clearFields();
      data ? (this.modalType = 'edit') : (this.modalType = 'add');
      this.$refs['programs-modal'].show();
      if (!this.genres || this.genres.length < 1) await this.$store.dispatch('GET_GENRES', { per_page: 1000 });
      if (data) {
        await this.$store.dispatch('GET_PROGRAMS_ID', data);
        this.writeTitle = this.modalEditPrograms.name;
        this.modalSelectedGenre = this.modalEditPrograms.genre;
        this.writeDuration = this.modalEditPrograms.duration;
      }
      this.isModalLoading = false;
    },

    async addEditPrograms() {
      this.isModalBusy = true;
      const formData = {
        id: this.modalType === 'add' ? undefined : this.modalEditPrograms.id,
        name: this.writeTitle,
        genre_id: this.modalSelectedGenre?.id,
        duration: this.writeDuration,
      };
      await this.$store.dispatch(this.modalType === 'add' ? 'POST_PROGRAMS' : 'PUT_PROGRAMS', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.modalType === 'add' ? this.$i18n.t('alert.addPrograms') : this.$i18n.t('alert.editPrograms'),
            text: this.writeTitle,
          });
          this.$refs['programs-modal'].hide();
          this.paramsData();
          this.clearFields();
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
      this.isModalBusy = false;
    },

    // modal delete

    async showModalDeletePrograms(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('table.confirmDeletePrograms')} "${data.name}"?`, {
          title: this.$i18n.t('table.deletePrograms'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'confirm-delete-modal',
        })
        .then((value) => {
          if (value) {
            const formData = data.id;
            const name = data.name;
            this.$store.dispatch('DELETE_PROGRAMS_ID', {
              formData,
              handler: () => {
                this.$notify({
                  type: 'success',
                  title: this.$i18n.t('alert.deletePrograms'),
                  text: name,
                });
                this.paramsData();
              },
              handlerError: (errors) => errorsHandler(errors, this.$notify),
            });
          }
        })
        .catch((err) => {});
    },

    checkIfValidThenEnter() {
      if (!this.$v.$invalid) {
        this.$bvModal
          .msgBoxConfirm(
            this.modalType === 'add'
              ? `${this.$i18n.t('table.confirmAddPrograms')} "${this.writeTitle}"?`
              : `${this.$i18n.t('table.confirmEditPrograms')} "${this.writeTitle}"?`,
            {
              title: this.modalType === 'add' ? this.$i18n.t('table.addPrograms') : this.$i18n.t('table.editPrograms'),
              size: 'sm',
              okTitle: this.$i18n.t('table.yes'),
              cancelTitle: this.$i18n.t('table.no'),
              autoFocusButton: 'ok',
              id: 'confirm-program-modal',
            }
          )
          .then((value) => {
            if (value) this.addEditPrograms();
          })
          .catch((err) => {});
      }
    },
  },
};
</script>

<style lang="sass"></style>
